<!--
 * @Author: Vinota
 * @Description: 公共头部
-->
<template>
    <header class="layout-header">
        <div class="head-left">
            <div class="logo">
                <img src="~@/assets/img/login/logo.png" />
                <h1>银河集团-香港身份智能申请系统</h1>
            </div>
            <div />
            <div class="head-menu">
                <div v-if="$store.state.gifted.stepEnd == 1 && $store.state.gifted.isList == 0">
                    <router-link to="/HandleProgress">首页</router-link>
                    <router-link to="/CommonInfo">基础信息</router-link>
                    <router-link to="/UploadData">上传资料</router-link>
                    <router-link to="/Manuscript">文书稿件</router-link>
                    <router-link to="/PostFile">邮寄原件</router-link>
                </div>
            </div>
        </div>
        <div class="head-right">
            <div class="user-block">
                <el-dropdown trigger="click">
                    <div class="user-wrapper">
                        <div class="user-img">
                            <img src="~@/assets/img/head.png" />
                        </div>
                        <span class="user-name">{{ $store.state.gifted.user.userName }}</span>
                        <i class="el-icon-arrow-down el-icon--right" />
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="personal" @click.native="seeProgress">选择产品</el-dropdown-item>
                        <el-dropdown-item command="loginout" @click.native="loginout">退出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </header>
</template>

<script lang="javascript">
import router from "@/router/index";
export default {
    name: "Header",
    components: {},
    data() {
        return {};
    },
    created() {
        // this.$store.commit("user");
        // this.$store.commit("setType");
        /**
         * session 数据
         * 列表 hasFinishOnePro  0-未开始 1-进行中
         * 步骤 step  项目7步骤 当前第几步
         * 当前订单类型 YhorderType 优才/专才/留学
         */
    },
    methods: {
        //退出清除token
        loginout() {
            this.$confirm("确认退出登录?", "", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                showClose: false,
            })
                .then(() => {
                    window.location.href = `${process.env.VUE_APP_CLIENT_URL}/#/Login`
                })
                .catch(() => {});
        },
        //查看项目列表
        seeProgress() {
            window.location.href = `${process.env.VUE_APP_CLIENT_URL}/#/ProjectList`
        },
    },
};
</script>

<style lang="less" scoped>
.layout-header {
    width: 100%;
    min-width: 1366px;
    height: 64px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 100;
    background: #ffffff;
    border-bottom: 1px solid #f0f2f5;

    .head-left {
        width: calc(100% - 460px);
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
        line-height: 20px;
        display: flex;
        align-items: center;
        padding-left: 9px;
        box-sizing: border-box;

        .logo {
            display: flex;
            align-items: center;

            img {
                display: block;
                width: 48px;
                height: 48px;
                margin-right: 8px;
            }

            h1 {
                font-size: 16px;
                color: #303133;
                font-weight: 600;
                margin-bottom: 3px;
                letter-spacing: 1px;
            }
        }

        .head-menu {
            margin-left: 50px;
            height: 48px;
            line-height: 48px;

            a {
                display: inline-block;
                padding: 0 16px;
                font-size: 14px;
                transition: background 0.3s ease-in;
                color: #909399;

                &:hover,
                &.router-link-active {
                    color: #409eff;
                }
            }
        }

        .nav-btns {
            display: flex;
            align-items: center;
            margin-left: 80px;

            .center-btn {
                width: 146px;
                height: 44px;
                background: rgb(255, 217, 0);
                background-size: 146px 44px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                color: #fff;
                margin-top: 22px;

                &:first-child {
                    margin-left: -10px;
                }
            }
        }
    }

    .head-right {
        width: 460px;
        height: 48px;
        padding: 0 25px 0 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;

        .user-block {
            display: flex;
            align-items: center;
            margin: 0 24px;

            .user-wrapper {
                display: flex;
                align-items: center;
                cursor: pointer;

                .user-img {
                    img {
                        width: 24px;
                        height: 24px;
                        border-radius: 12px;
                    }
                }

                .user-name {
                    font-size: 14px;
                    color: #909399;
                    margin: 0 2px 0 5px;
                }
            }
        }
    }
}

/deep/.el-dropdown {
    top: 8px;
}
</style>

<!--
 * @Author: Vinota
 * @Description: 公共底部
-->
<template>
    <div class="footer-box">
        <div class="logo-icon">
            <div class="icon-size">
                <div class="qq-window" />
            </div>
            <div class="d-icon-size">
                <div class="d-window" />
            </div>
        </div>
        <div class="f-font">让新中产的美好生活有更多的选择</div>
        <div class="copy-right">
            <span>深圳市银河世纪投资咨询有限公司版权所有 ©2007-{{ year }} </span>
            <span class="blue-color" @click="goAgreement">服务协议</span>
        </div>
    </div>
</template>

<script lang="javascript">
export default {
    name: "Footer",
    components: {},
    data() {
        return {
            year: 0,
        };
    },
    mounted() {
        this.year = new Date().getFullYear();
    },
    methods: {
        goAgreement() {
            let hrefs = this.$router.resolve({
                path: '/Agreement'
            });
            window.open(hrefs.href, '_blank')
        }
    },

};
</script>

<style lang="less" scoped>
.el-footer {
    padding: 0 0px;
}

.footer-box {
    width: 100%;
    height: 130px;
    background: #F2F7FF;
}

.logo-icon {
    width: 105px;
    height: 24px;
    margin: 0 auto;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.icon-size {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: red;
    position: relative;
    z-index: 1000;
    background: url("~@/assets/img/link_icon.png") no-repeat center center;

    .qq-window {
        display: none;
        background: green;
        width: 98px;
        height: 98px;
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        background: url("~@/assets/img/sun_qr_detail.png") no-repeat center center;
        background-size: cover;
    }

    &:hover {
        .qq-window {
            display: block;
        }

    }

}

.d-icon-size {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
    background: url("~@/assets/img/weixin_icon.png") no-repeat center center;
    z-index: 1000;
    .d-window {
        display: none;
        width: 98px;
        height: 98px;
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        background: url("~@/assets/img/weixin_code.png") no-repeat center center;
        background-size: cover;
        opacity: 0;
        transition: all .2s ease-in-out;
    }

    &:hover {
        .d-window {
            display: block;
            opacity: 1;
        }

    }

}

.f-font {
    font-size: 14px;
    font-weight: 600;
    color: #303133;
    width: 100%;
    height: 20px;
    text-align: center;
    margin-top: 10px;
    padding-bottom: 36px;
    border-bottom: 1px solid #DCDFE6;
}

.copy-right {
    font-size: 12px;
    font-weight: 400;
    color: #606266;
    text-align: center;
    padding-top: 10px;
}

.blue-color {
    color: #409EFF;
    cursor: pointer;
}
</style>

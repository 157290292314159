<template>
    <el-container>
        <el-header>
            <Header />
        </el-header>
        <router-view />
        <Footer />
    </el-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Header from "../../components/Header/Header.vue";
import Footer from "../../components/Footer/Footer.vue";
export default {
    name: "Layout",
    components:{
        Header, Footer
    },
    computed: {
        ...mapState(["userFrom"]),
    },
    created() {
        this.checkUrl()
    },
    methods: {
        ...mapActions(["getUserFrom"]),
        checkUrl() {
            if(this.$route.path === '/') window.location.href = `${process.env.VUE_APP_CLIENT_URL}/#/Login`
        },
    },
};
</script>

<style lang="less" scoped>

</style>
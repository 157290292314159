import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=1877d4f2&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=javascript&"
export * from "./Header.vue?vue&type=script&lang=javascript&"
import style0 from "./Header.vue?vue&type=style&index=0&id=1877d4f2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1877d4f2",
  null
  
)

export default component.exports